export function getSubscriptionIcon(subscription: string): string {
  let subscriptionIcon = ""
  switch (subscription) {
    case "BRONZE":
      subscriptionIcon = "podium-bronze"
      break
    case "SILVER":
      subscriptionIcon = "podium-silver"
      break
    case "GOLD":
      subscriptionIcon = "podium-gold"
      break
    case "FIRST_YEAR":
      subscriptionIcon = "bird"
      break
    default:
      subscriptionIcon = "poll"
  }
  return subscriptionIcon
}
